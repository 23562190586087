<template>
  <a-spin :spinning="spinning">
    <div :class="{ disabled: disabled }">
      <a-form-model layout="inline" :model="adjustForm" :rules="rules" ref="adjustForm" style="margin-top:3px">
        <a-card class="card" title="业务信息" :bordered="false" style="margin-top:3px;line-height: 1;">
          <div slot="extra">
            <a-affix
              :offsetTop="50"
              :style="{ position: 'absolute', top: '15px', left: 0, width: '98%', 'text-align': 'right' }"
            >
              <a-button><router-link :to="{ name: 'CumsAdjust' }">取消</router-link></a-button>
              <a-divider type="vertical" />
              <a-button :disabled="disabled ? true : false" type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
            </a-affix>
          </div>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="业务编号" prop="serial_num">
                <a-input v-model.trim="adjustForm.serial_num" @blur="handleDetailBySerialNum(adjustForm.serial_num)"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="进库核注单号">
                <a-input v-model="adjustForm.check_num" disabled/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="结算单" prop="balance_id">
                <a-select
                  placeholder="请选择结算单"
                  v-model="adjustForm.balance_id"
                  style="width: 300px"
                  @change="handleBalanceIdChange"
                >
                  <a-select-option v-for="op in orderBalanceList" :key="op.id">{{ op.serial_num }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="调整项目（修改费用通过删除费用、添加费用操作）" :selfUpdate="false" prop="adjust_types">
                <a-checkbox-group :options="adjustTypeOps" v-model="adjustForm.adjust_types">
                  <span slot="label" slot-scope="option">{{ option.name }}</span>
                </a-checkbox-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="备注">
                <a-input style="width: 600px" v-model.trim="adjustForm.remark"/>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
        <a-card v-if="adjustForm.adjust_types.indexOf('重新开票')>=0 || adjustForm.adjust_types.indexOf('作废发票')>=0" class="card pc"
                :title="adjustForm.adjust_types.indexOf('作废发票')>=0?'作废发票':'重新开票'" :bordered="false"
                style="margin-top:-3px;line-height: 0.5;">
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="原发票">
                <a-input style="width: 600px" placeholder="对账发票用;分割" v-model="adjustForm.original_invoices"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="发票备注">
                <a-textarea style="width: 600px" placeholder="对账发票用;分割" v-model="adjustForm.invoice_remark"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <edit-table
                :columns="invoiceSubjectColumns"
                :sourceData="invoiceSubjectData"
                :scroll="true"
                :operate="{ add: true, del: true, alldel: false }"
                @getTableDate="getInvoiceSubjectTableDate"></edit-table>
            </a-col>
            <a-col :span="12">
              <a-form-model-item>
                <span style="font-weight:700;color:red">含税总额：{{ this.totalInvoiceAmount }}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
        <a-card v-if="adjustForm.adjust_types.indexOf('新增费用')>=0" class="card pc" title="新增费用" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-tabs default-active-key="1">
            <a-tab-pane tab="应收费用" key="1" forceRender>
              <edit-table
                scroll
                :columns="chargeFeeColumns"
                :sourceData="chargeFeeData"
                :feeStatusFlag="true"
                @getTableDate="getChargeFeeData"
                @getTableDateError="getTableDateError"></edit-table>
            </a-tab-pane>
            <a-tab-pane tab="应付费用" key="2" forceRender>
              <edit-table
                scroll
                ref="order_pay_fee"
                :columns="payFeeColumns"
                :sourceData="payFeeData"
                :feeStatusFlag="true"
                @getTableDate="getPayFeeData"
                @getTableDateError="getTableDateError"></edit-table>
            </a-tab-pane>
            <template slot="tabBarExtraContent">
              <span style="margin-right: 20px">应收：{{ totalChargeFee.toFixed(2) }} </span>
              <span style="margin-right: 20px"> 应付：{{ totalPayFee.toFixed(2) }} </span>
              <span style="margin-right: 100px"> 利润：{{ (totalChargeFee - totalPayFee).toFixed(2) }} </span>
            </template>
          </a-tabs>
        </a-card>
        <a-card v-if="adjustForm.adjust_types.indexOf('删除费用')>=0" class="card pc" title="删除费用" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-table
            ref="table"
            size="small"
            row-key="id"
            :filterColumn="true"
            :columns="feeColumns"
            :data-source="originalFeeData"
            :row-selection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
            :scroll="{ x: 'max-content' }"
            :pagination="false">
            <template slot="feeTypeSlot" slot-scope="text">
              <a-tag :color="text===1?'blue':'red'">{{text===1?'应收':'应付'}}</a-tag>
            </template>
          </a-table>
        </a-card>
        <a-card v-if="adjustForm.adjust_types.indexOf('加入结算单')>=0" class="card pc" title="加入结算单" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-table
            ref="table"
            size="small"
            row-key="id"
            :filterColumn="true"
            :columns="feeColumns"
            :data-source="noBalanceFeeData"
            :row-selection="{ selectedRowKeys: this.nobalanceSelectedRowKeys, onChange: this.onNobalanceSelectChange }"
            :scroll="{ x: 'max-content' }"
            :pagination="false">
            <template slot="feeTypeSlot" slot-scope="text">
              <a-tag :color="text===1?'blue':'red'">{{text===1?'应收':'应付'}}</a-tag>
            </template>
          </a-table>
        </a-card>
        <a-card v-if="adjustForm.adjust_types.indexOf('修改客户')>=0" class="card pc" title="修改客户" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="原客户名称">
                <a-input style="width: 300px" v-model="adjustForm.original_customer_name" disabled/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="新客户名称">
                <a-select
                  showSearch
                  label-in-value
                  :filter-option="false"
                  allowClear
                  placeholder="请选择委托单位"
                  style="width: 300px"
                  :value="customerInfo"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in agencyOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
      </a-form-model>
    </div>
  </a-spin>
</template>
<script>
import { getCommonOptions, getCustomer, getSupplier } from '@/api/common'
import { getFirstLetter } from '@/utils/util'
import {
  getImportDetailBySerialNo,
  getClearDetailBySerialNo,
  saveAdjustOrder,
  initCumsAdjust
} from '@/api/cums'
import { EditTable, BoxItemGroup, ItemGroup } from '@/components'
import moment from 'moment'
import preventReClick from '@/utils/preventReClick' // 防多次点击，重复提交
import debounce from 'lodash/debounce'
import { listSecondInvoiceSubject } from '@/api/fms'
export default {
  components: {
    EditTable,
    BoxItemGroup,
    ItemGroup,
    preventReClick
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      adjustForm: {
        order_id: null,
        balance_id: [],
        balance_num: null,
        serial_num: null,
        check_num: null,
        adjust_types: [],
        remark: null,
        original_customer_id: null,
        original_customer_name: null,
        new_customer_id: null,
        new_customer_name: null,
        add_charge_fees: [],
        add_pay_fees: [],
        delete_fee_ids: [],
        original_invoices: null
      },
      orderBalanceList: [],
      orderPaymentList: [],
      originalFeeData: [],
      noBalanceFeeData: [],
      selectedRowKeys: [],
      selectedRows: [],
      nobalanceSelectedRowKeys: [],
      nobalanceSelectedRows: [],
      // /////////////
      businessExpand: true,
      allocationExpand: true,
      billInfoExpand: true,
      confirmLoading: false,
      spinning: false,
      isEdit: false,
      customerInfo: undefined,
      fetching: false,
      colSpan: {
        xxl: 6,
        lg: 7,
        md: 8
      },
      visible: false,
      disabled: false,
      box_info: [{ id: null, num: null, box_num: '' }],
      store_type: [], // 型号
      trans_num: [], // 航次和航班号公用
      send_info: [],
      eng_name: [],
      agencyOps: [],
      agentOps: [], // 订舱代理
      agentAboardOps: [],
      customOps: [],
      adjustTypeOps: [],
      payTargets: [],
      bsData: [], // 保税信息
      chargeFeeData: [],
      payFeeData: [],
      chargeFeeOps: [],
      taxRateList: [{ value: 0, name: 0 },
        { value: 0.01, name: 0.01 },
        { value: 0.03, name: 0.03 },
        { value: 0.05, name: 0.05 },
        { value: 0.06, name: 0.06 },
        { value: 0.09, name: 0.09 },
        { value: 0.13, name: 0.13 },
        { value: 0.15, name: 0.15 },
        { value: 0.16, name: 0.16 },
        { value: 0.17, name: 0.17 }],
      payFeeOps: [],
      totalInvoiceAmount: 0.00,
      invoiceSubjectColumns: [
        {
          title: `货物或应税劳务,服务名称`,
          dataIndex: `goods_name`,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  let subject = null
                  for (const item of this.secondInvoiceSubjectList) {
                    if (item.id === val) {
                      subject = item
                    }
                  }
                  this.invoiceSubjectData[index]['subject_id'] = subject.id
                  this.invoiceSubjectData[index]['goods_name'] = subject.subject_name
                  this.invoiceSubjectData[index]['goods_code'] = subject.subject_code
                  this.invoiceSubjectData[index]['goods_specification'] = subject.subject_spec
                  this.invoiceSubjectData[index]['goods_unit'] = subject.subject_unit
                  this.invoiceSubjectData[index]['goods_tax_rate'] = subject.tax_rate
                }}
              >
                {this.secondInvoiceSubjectList.map(op => {
                  var opName = op.subject_name
                  if (op.subject_spec) {
                    opName = opName + '/' + op.subject_spec
                  }
                  opName = opName + '/' + op.tax_rate
                  return <a-select-option key={op.id}>{opName}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `金额`,
          dataIndex: `goods_total_price`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_price'] = val
                  if (this.invoiceSubjectData[index]['goods_tax_rate']) {
                    var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (val / (1 + taxRate) * taxRate).toFixed(2)
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `税率`,
          dataIndex: `goods_tax_rate`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_tax_rate'] = val
                  if (this.invoiceSubjectData[index]['goods_total_price']) {
                    var totalPrice = this.invoiceSubjectData[index]['goods_total_price']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice / (1 + val) * val).toFixed(2)
                  }
                  this.calcTotalPrice(false)
                }}
              >
                {this.taxRateList.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `税额`,
          dataIndex: `goods_total_tax`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_tax'] = val
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        }
      ],
      invoiceSubjectData: [],
      chargeFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-select style="width: 100%" value={text}
                showSearch
                disabled={record.disabled}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = 0
                  for (const item of this.chargeFeeOps) {
                    if (item.value === val) {
                      value = item.attribution_fee
                    }
                  }
                  this.chargeFeeData[index]['fee_name_type'] = val
                  this.chargeFeeData[index]['fee_class'] = value
                }}>
                  {
                    this.chargeFeeOps.map(op => {
                        return (<a-select-option key={op.value}>{op.name}</a-select-option>)
                    })
                  }
              </a-select>
            )
          }
        },
        {
          title: '费用类型',
          dataIndex: 'fee_class',
          width: 150,
          extra: {
            type: 'select',
            options: [{ value: 0, name: '仓储包干收入' }, { value: 1, name: '报关报检收入' }, { value: 2, name: '代理运输收入' }, { value: 3, name: '租赁服务收入' }],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 1,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          width: 100,
          extra: {
            extraFunc: this.calcTotalCharge,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            defaultValue: 19,
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_num',
          extra: {
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason[0].deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预收',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预收', value: 1 },
              { name: '预收', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      payFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-select style="width: 100%" value={text}
                showSearch
                disabled={record.disabled}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = 0
                  for (const item of this.payFeeOps) {
                    if (item.value === val) {
                      value = item.attribution_fee
                    }
                  }
                  this.payFeeData[index]['fee_name_type'] = val
                  this.payFeeData[index]['fee_class'] = value
                }}>
                  {
                    this.payFeeOps.map(op => {
                        return (<a-select-option key={op.value}>{op.name}</a-select-option>)
                    })
                  }
              </a-select>
            )
          }
        },
        {
          title: '费用类型',
          dataIndex: 'fee_class',
          width: 150,
          extra: {
            type: 'select',
            options: [{ value: 0, name: '仓储包干收入' }, { value: 1, name: '报关报检收入' }, { value: 2, name: '代理运输收入' }, { value: 3, name: '租赁服务收入' }],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 2,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          extra: {
            extraFunc: this.calcTotalPay,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            defaultValue: 19,
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '付款对象',
          dataIndex: 'pay_target_id',
          extra: {
            type: 'select',
            func: getSupplier,
            params: [ { department: 7 } ],
            // flexible: true,
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason[0].deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预付',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预付', value: 1 },
              { name: '预付', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '付款单',
          dataIndex: 'payment_id',
          width: 300,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                onChange={val => {
                  this.payFeeData[index]['payment_id'] = val;
                }}
              >
                {this.orderPaymentList.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        }
      ],
      feeColumns: [
        {
          title: '费用类型',
          dataIndex: 'fee_type',
          scopedSlots: { customRender: 'feeTypeSlot' }
        },
        {
          title: '费用名称',
          dataIndex: 'fee_name'
        },
        {
          title: '金额',
          dataIndex: 'money'
        },
        {
          title: '币种',
          dataIndex: 'currency',
          customRender: (text, record) => {
            return text && text === 12 ? '美元' : '人民币'
          }
        },
        {
          title: '付款对象',
          dataIndex: 'pay_target_name'
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason[0].deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '预收预付',
          dataIndex: 'is_advance',
          customRender: (text, record) => {
            return text && text === 2 ? '是' : '否'
          }
        }
      ],
      // 应收金额统计展示
      totalChargeFee: 0,
      // 应付金额统计展示
      totalPayFee: 0,
      // 表单校验
      rules: {
        // 报价编号
        serial_num: [{ required: true, message: '请输入业务编号', trigger: 'blur' }],
        // 业务类型
        business_type: [{ required: true, message: '请选择业务类型', trigger: 'blur' }],
        // 服务类型
        service_types: [{ required: true, message: '请选择服务类型', trigger: 'blur' }],
        balance_id: [{ required: true, message: '请选择结算单', trigger: 'blur' }]
      }
    }
  },
  watch: {
    $route: function(newRoute) {
    }
  },
  created() {
  },
  mounted() {
    this.getBasicData()
    this.resetAdjustForm()
    this.init()
  },
  methods: {
    init() {
      initCumsAdjust().then(res => {
        this.adjustTypeOps = res.ADJUST_TYPE_LIST
        // this.adjustStatusOps = res.ADJUST_STATUS_LIST
      })
      listSecondInvoiceSubject({ department: 7 }).then(v => {
        this.secondInvoiceSubjectList = v
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    onNobalanceSelectChange(nobalanceSelectedRowKeys, nobalanceSelectedRows) {
      this.nobalanceSelectedRowKeys = nobalanceSelectedRowKeys
      this.nobalanceSelectedRows = nobalanceSelectedRows
    },
    toggle(type) {
      if (type === 'business') {
        this.businessExpand = !this.businessExpand
      } else if (type === 'allocation') {
        this.allocationExpand = !this.allocationExpand
      } else if (type === 'billInfo') {
        this.billInfoExpand = !this.billInfoExpand
      }
    },
    radioChange() {
      const type = this.form['business_type'];
      if (type === 3 || type === 4) {
        this.form['agent_aboard'] = null;
      }
    },
    getTableDateError() {
      this.$notification['error']({
        message: '提示',
        description: '该订单不可删除！'
      })
    },
    moment,
    handleFormReset() {
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.agencyOps = []
      this.fetching = true
      getCustomer({
        department: 7,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.agencyOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.adjustForm.new_customer_id = value.key
      this.adjustForm.new_customer_name = value.label
      this.agencyOps = []
      this.fetching = false
    },
    handleDetailBySerialNum(serialNum) {
      this.originalFeeData = []
      this.orderBalanceList = []
      this.orderPaymentList = []
      this.noBalanceFeeData = []
      if (!serialNum) {
        this.resetAdjustForm()
        return
      }
      if (serialNum.indexOf('OGKFR') >= 0) {
        this.adjustForm.service_type = 0
      } else if (serialNum.indexOf('OGKFQ') >= 0) {
        this.adjustForm.service_type = 1
      } else {
        this.$notification['error']({
          message: '提示',
          description: '业务调整只支持接单、清关业务'
        })
        return
      }
      this.spinning = true
      if (this.adjustForm.service_type === 0) {
        getImportDetailBySerialNo({ serialNum: serialNum }).then(v => {
          this.adjustForm.order_id = v.order.id
          this.adjustForm.serial_num = v.order.serial_num
          this.adjustForm.check_num = v.order.check_num
          this.adjustForm.original_customer_name = v.order.agency_name
          this.adjustForm.original_customer_id = v.order.auth_agency
          this.orderBalanceList = v.balances
          this.orderPaymentList = []
          v.payments && v.payments.forEach(item => {
            this.orderPaymentList.push({ value: item.id, name: item.customer_name + item.serial_num })
          })
          v.charge_fees.forEach(item => {
            this.originalFeeData.push(item);
            if (item.is_balance === 1) {
              this.noBalanceFeeData.push(item);
            }
          })
          const uniqueInvoiceNums = new Set(v.charge_fees.map(item => item.invoice_num));
          this.adjustForm.original_invoices = Array.from(uniqueInvoiceNums).join(';')
          v.pay_fees.forEach(item => {
            this.originalFeeData.push(item);
            if (item.is_balance === 1) {
              this.noBalanceFeeData.push(item);
            }
          })
          this.spinning = false
        }).catch(_ => {
          this.spinning = false
        })
      } else if (this.adjustForm.service_type === 1) {
        getClearDetailBySerialNo({ serialNum: serialNum }).then(v => {
          this.adjustForm.order_id = v.order.id
          this.adjustForm.serial_num = v.order.serial_num
          this.adjustForm.check_num = v.order.check_num
          this.adjustForm.original_customer_name = v.order.agency_name
          this.adjustForm.original_customer_id = v.order.auth_agency
          this.orderBalanceList = v.balances
          this.orderPaymentList = []
          v.payments && v.payments.forEach(item => {
            this.orderPaymentList.push({ value: item.id, name: item.customer_name + item.serial_num })
          })
          v.charge_fees.forEach(item => {
            this.originalFeeData.push(item);
            if (item.is_balance === 1) {
              this.noBalanceFeeData.push(item);
            }
          })
          const uniqueInvoiceNums = new Set(v.charge_fees.map(item => item.invoice_num));
          this.adjustForm.original_invoices = Array.from(uniqueInvoiceNums).join(';')
          v.pay_fees.forEach(item => {
            this.originalFeeData.push(item);
            if (item.is_balance === 1) {
              this.noBalanceFeeData.push(item);
            }
          })
          this.spinning = false
        }).catch(_ => {
          this.spinning = false
        })
      }
    },
    resetAdjustForm() {
      this.adjustForm = {
        order_id: null,
        balance_id: null,
        balance_num: null,
        serial_num: null,
        check_num: null,
        adjust_types: [],
        remark: null,
        original_customer_id: null,
        original_customer_name: null,
        new_customer_id: null,
        new_customer_name: null,
        add_charge_fees: [],
        add_pay_fees: [],
        delete_fee_ids: [],
        original_invoices: null,
        invoice_remark: null
      }
      this.originalFeeData = []
      this.customerInfo = null
      this.noBalanceFeeData = []
    },
    getBasicData() {
      getCommonOptions('fee', { department: 7, type: 1 }).then(v => {
        this.chargeFeeOps = v
      })
      getCommonOptions('fee', { department: 7, type: 2 }).then(v => {
        this.payFeeOps = v
      })
    },
    handleAddOrder() {
      // 加拼
      this.visible = true
    },
    getOrderData(data) {
      this.orderData = data
    },
    getBsData(data) {
      this.bsData = data
    },
    getChargeFeeData(data) {
      this.chargeFeeData = data
    },
    getPayFeeData(data) {
      this.payFeeData = data
    },
    calcTotalCharge(value, newData) {
      let money = 0
      this.chargeFeeData.forEach(v => {
        money += parseFloat(v.money)
      })
      this.totalChargeFee = money
      return newData
    },
    calcTotalPay(value, newData) {
      let money = 0
      this.payFeeData.forEach(v => {
        money += parseFloat(v.money)
      })
      this.totalPayFee = money
      return newData
    },
    handleAutoSearch(inputValue, option) {
      if (inputValue) {
        return option.key.toLowerCase().includes(inputValue.toLowerCase())
      } else {
        return true
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    calcTotalInfo(value, newData, target) {
      let totalNum = 0
      let totalVolumn = 0
      newData.forEach(row => {
        totalNum += row.num || 0
        totalVolumn += parseFloat(row.volume) || 0
      })
      this.form.setFieldsValue({ total_num: totalNum, volume: totalVolumn })
      return newData
    },
    handleSubmit() {
      this.$refs.adjustForm.validate(valid => {
        if (valid) {
          this.adjustForm.add_charge_fees = this.chargeFeeData
          this.adjustForm.add_pay_fees = this.payFeeData
          this.adjustForm.delete_fee_ids = this.selectedRowKeys
          this.adjustForm.add_exists_fee_ids = this.nobalanceSelectedRowKeys
          this.adjustForm.invoice_subject_data = this.invoiceSubjectData
          saveAdjustOrder(this.adjustForm).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            });
            this.disabled = true
          });
        } else {
          return false;
        }
      });
    },
    handleBalanceIdChange() {
      if (this.adjustForm.balance_id) {
        var orginalInoivces = []
        this.orderBalanceList.forEach(element => {
            if (element.id === this.adjustForm.balance_id) {
              orginalInoivces.push(element.invoice_nos)
              this.adjustForm.invoice_remark = element.invoice_remark
            }
          });
        this.adjustForm.original_invoices = Array.from(orginalInoivces).join(';')
      } else {
        this.adjustForm.original_invoices = ''
      }
    },
    getInvoiceSubjectTableDate(data) {
      this.invoiceSubjectData = data
    },
    calcTotalPrice(againFlag) {
      this.totalInvoiceAmount = 0
      this.invoiceSubjectData.forEach(v => {
        if (againFlag) {
          if (v.goods_quantity && v.goods_price && v.goods_tax_rate) {
            v.goods_total_price = v.goods_quantity * v.goods_price
            v.goods_total_tax = (v.goods_total_price / (1 + v.goods_tax_rate) * v.goods_tax_rate).toFixed(2)
          }
        }
        if (v.goods_total_price) {
          this.totalInvoiceAmount = this.totalInvoiceAmount + v.goods_total_price
        }
      })
    }
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 10px;
}
.table-title {
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 800;
  /* color: #1890ff */
}
.field-item {
  position: relative;
  width: 200px;
  margin-right: 10px;
  margin-top: 10px;
  color: red;
}
.field-item:last-child {
  width: 280px;
}
.field-item_del {
  position: absolute;
  top: -5px;
  left: 190px;
  color: #ccc;
  z-index: 999;
  cursor: pointer;
}
.field-item_del:hover,
.send-item_del:hover {
  color: #1890ff;
}
</style>
<style>
.pc .ant-drawer {
  display: none;
}
.pc .ant-drawer-open {
  display: block;
}
.field-item.store-type-item,
.field-item.store-type-item:last-child {
  width: 100%;
}
.field-item.store-type-item .field-item_del {
  left: calc(100% - 110px);
}
.field-item.send-item,
.field-item.send-item:last-child {
  width: 100%;
}
.field-item.send-item .field-item_del {
  left: calc(100% - 110px);
}
.ant-card-body {
    padding: 12px;
    zoom: 1;
}
.ant-table-placeholder {
    padding: 6px;
}
.ant-card-head {
    min-height: 36px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    background: transparent;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 2px 2px 0 0;
    zoom: 1;
}
.ant-card-head-title {
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 16px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 1000
}
</style>
